<template>
  <q-page class="q-py-md q-px-xl">
    <div class="row q-gutter-xl">
      <q-card class="my-card">
        <q-card-section class="q-py-xs">Data Terhimpun</q-card-section>
        <q-card-section class="text-h5 text-center text-bold text-white">{{ count_kajian }}</q-card-section>
        <q-card-section class="q-py-xs text-right">Dari {{ count_guru }} Guru</q-card-section>
      </q-card>
      <q-card class="my-card">
        <q-card-section class="q-py-xs">Hadir</q-card-section>
        <q-card-section class="text-h5 text-center text-bold text-white">{{ count_hadir }}</q-card-section>
        <q-card-section class="q-py-xs text-right">Orang</q-card-section>
      </q-card>
      <q-card class="my-card">
        <q-card-section class="q-py-xs">Sakit/Izin/Alfa</q-card-section>
        <q-card-section class="text-h5 text-center text-bold text-white">
          {{ count_sakit }} / {{ count_izin }} /
          {{ count_alfa }}
        </q-card-section>
        <q-card-section class="q-py-xs text-right">Orang</q-card-section>
      </q-card>
      <q-card class="my-card">
        <q-card-section class="q-py-xs">Tidak Berjalan</q-card-section>
        <q-card-section class="text-h5 text-center text-bold text-white">{{ count_tidak_berjalan }}</q-card-section>
        <q-card-section class="q-py-xs text-right">Orang</q-card-section>
      </q-card>
    </div>

    <div class="full-width">
      <q-card class="full-width q-my-md">
        <q-card-section horizontal class="row justify-between">
          <q-card-section>
            <q-select
              filled
              emit-value
              map-options
              dense
              v-model="selectPeriod"
              :options="optionPeriod"
              style="min-width:300px"
              label="Periode Arsip"
              @input="fetchData(orderBy)"
            />
          </q-card-section>
          <q-card-section>
            <q-btn
              outline
              no-caps
              :disabled="!selectPeriod"
              color="indigo-10"
              label="Download Arsip"
              @click="downloadArsip"
            />
          </q-card-section>
        </q-card-section>
      </q-card>
      <q-card flat bordered>
        <q-card-section class="row justify-between items-end">
          <div v-if="range_date.from" class="text-grey">
            Periode : {{ range_date.from | moment("DD MMMM YYYY") }} s.d
            {{ range_date.to | moment("DD MMMM YYYY") }}
          </div>
          <div v-else class="text-grey text-italic">-- s.d. --</div>

          <div class="row q-gutter-md items-end">
            <q-select
              label="Filter by Divisi:"
              filled
              dense
              map-options
              v-model="seldiv"
              use-input
              hide-selected
              fill-input
              input-debounce="0"
              :options="filtereddiv"
              style="max-width:150px"
              @input="fetchData('')"
              @filter="filterFn"
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">No results</q-item-section>
                </q-item>
              </template>
            </q-select>
            <q-input
              filled
              v-model="search"
              placeholder="Cari Nama"
              dense
              style="border-radius: 5px; width: 350px;"
              @keypress.enter="fetchData('nama')"
            >
              <template v-slot:append>
                <q-btn
                  flat
                  icon="search"
                  class="text-white absolute-right"
                  @click="fetchData('nama')"
                  style="background-color:#03913F"
                />
              </template>
            </q-input>
          </div>
        </q-card-section>
        <q-markup-table bordered dense class="col-12">
          <thead class="text-blue-grey-14">
            <tr class="text-left">
              <th>No Induk</th>
              <th>Nama</th>
              <th>Gender</th>
              <th>Divisi</th>
              <th>Status Kehadiran</th>
            </tr>
          </thead>
          <tbody v-if="listData.length" class>
            <tr v-for="d in listData" :key="d.spp_id">
              <td>{{ d.no_induk }}</td>
              <td>{{ d.nama }}</td>
              <td>{{ d.jenis_kelamin }}</td>
              <td>{{ d.divisi }}</td>
              <td>{{ d.status_kehadiran }}</td>
            </tr>
          </tbody>
          <tbody v-else class="bg-green-1">
            <tr>
              <td class="text-center text-grey" colspan="99">tidak ada data</td>
            </tr>
          </tbody>
        </q-markup-table>
        <q-card-actions align="right">
          <q-pagination
            v-model="activePage"
            :max="Math.ceil(count_page / 10)"
            input
            input-class="text-orange-10"
            @click="fetchData(orderBy)"
          />
        </q-card-actions>
      </q-card>
    </div>
  </q-page>
</template>

<style></style>

<script>
import XLSX from "xlsx/xlsx";

export default {
  name: "Result",
  data() {
    return {
      seldiv: "Semua Divisi",
      filtereddiv: [],
      selectPeriod: null,
      optionPeriod: [],
      listData: [],
      listData_all: [],
      count_hadir: 0,
      count_sakit: 0,
      count_izin: 0,
      count_alfa: 0,
      count_guru: 0,
      count_kajian: 0,
      count_page: 0,
      count_tidak_berjalan: 0,
      orderBy: "",
      orderType: "",
      activePage: 1,
      range_date: {},
      kode_unik: "ARRCVD",
      search: "",
      confirm: false,

      excelColumns: [
        {
          label: "Tanggal",
          field: "tanggal",
          dataFormat: this.dateFormat
        },
        {
          label: "No Induk",
          field: "no_induk"
        },
        {
          label: "Nama",
          field: "nama"
        },
        {
          label: "Status Kehadiran",
          field: "status_kehadiran"
        }
      ]
    };
  },
  mounted() {
    this.fetchData("no_induk");
  },
  methods: {
    filterFn(val, update, abort) {
      update(() => {
        const needle = val.toLowerCase();
        this.filtereddiv = this.$DIVISI.filter(
          v => v.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    dateFormat(val) {
      return this.$moment(val).format("DD-MMM-YYYY HH:MM:SS");
    },
    async downloadArsip() {
      this.seldiv = "Semua Divisi";
      await this.fetchData();

      let columns = this.excelColumns;
      let data = this.listData_all;
      let divisi = this.$DIVISI;
      let filename = "Arsip Kehadiran Halaqoh Guru " + this.selectPeriod;
      let sheetname = "Kehadiran Halaqoh";

      let guruALL = [];

      // get data guru per divisi
      divisi.forEach(element => {
        guruALL.push(data.filter(x => x.divisi == element));
      });

      let createXLSLFormatObj = [];
      let newXlsHeader = [];
      let vm = this;
      if (columns.length === 0) {
        return;
      }
      if (data.length === 0) {
        return;
      }

      columns.forEach((value, index) => {
        newXlsHeader.push(value.label);
      });

      for (let i in guruALL) {
        let tempOBJ = [];
        guruALL[i].forEach((value, index) => {
          let innerRowData = [];
          columns.forEach((val, index) => {
            if (val.dataFormat && typeof val.dataFormat === "function") {
              innerRowData.push(val.dataFormat(value[val.field]));
            } else {
              innerRowData.push(value[val.field]);
            }
          });
          tempOBJ.push(innerRowData);
        });
        tempOBJ.unshift(newXlsHeader);
        createXLSLFormatObj.push(tempOBJ);
      }

      let createXLSLSummaryObj = [];
      createXLSLSummaryObj.push(["Data Terhimpun : ", this.count_kajian]);
      createXLSLSummaryObj.push(["Hadir : ", this.count_hadir]);
      createXLSLSummaryObj.push(["Sakit : ", this.count_sakit]);
      createXLSLSummaryObj.push(["Izin : ", this.count_izin]);
      createXLSLSummaryObj.push(["Alfa : ", this.count_alfa]);
      createXLSLSummaryObj.push([
        "Tidak Berjalan : ",
        this.count_tidak_berjalan
      ]);
      createXLSLSummaryObj.push([
        "Periode : ",
        this.$moment(this.range_date.from).format("DD-MMM-YYYY") +
          " hingga " +
          this.$moment(this.range_date.to).format("DD-MMM-YYYY")
      ]);

      filename = filename + ".xlsx";
      let ws_name = sheetname;
      let wb = XLSX.utils.book_new(),
        wsG_MA = XLSX.utils.aoa_to_sheet(createXLSLFormatObj[1]),
        wsG_SMPPA = XLSX.utils.aoa_to_sheet(createXLSLFormatObj[2]),
        wsG_SMPPI = XLSX.utils.aoa_to_sheet(createXLSLFormatObj[3]),
        wsG_STEI = XLSX.utils.aoa_to_sheet(createXLSLFormatObj[4]),
        wsG_mahad = XLSX.utils.aoa_to_sheet(createXLSLFormatObj[5]),
        wsG_SDQ = XLSX.utils.aoa_to_sheet(createXLSLFormatObj[6]),
        wsG_RA = XLSX.utils.aoa_to_sheet(createXLSLFormatObj[7]),
        wsG_BPW = XLSX.utils.aoa_to_sheet(createXLSLFormatObj[8]),
        wsG_LDS = XLSX.utils.aoa_to_sheet(createXLSLFormatObj[9]),
        wsG_KY = XLSX.utils.aoa_to_sheet(createXLSLFormatObj[10]),
        wsG_SY = XLSX.utils.aoa_to_sheet(createXLSLFormatObj[11]),
        wsG_HY = XLSX.utils.aoa_to_sheet(createXLSLFormatObj[12]),
        wsG_ADM = XLSX.utils.aoa_to_sheet(createXLSLFormatObj[13]),
        wsG_SDM = XLSX.utils.aoa_to_sheet(createXLSLFormatObj[14]),
        wsG_RT = XLSX.utils.aoa_to_sheet(createXLSLFormatObj[15]),
        wsG_sarpras = XLSX.utils.aoa_to_sheet(createXLSLFormatObj[16]),
        wsG_usaha = XLSX.utils.aoa_to_sheet(createXLSLFormatObj[17]),
        wsG_pengasuhan_pa = XLSX.utils.aoa_to_sheet(createXLSLFormatObj[18]),
        wsG_pengasuhan_pi = XLSX.utils.aoa_to_sheet(createXLSLFormatObj[19]),
        wsG_perguruan = XLSX.utils.aoa_to_sheet(createXLSLFormatObj[20]),
        wsG_pustaka = XLSX.utils.aoa_to_sheet(createXLSLFormatObj[21]),
        wsG_laboran = XLSX.utils.aoa_to_sheet(createXLSLFormatObj[22]),
        wsG_TPA = XLSX.utils.aoa_to_sheet(createXLSLFormatObj[23]),
        wsSUM = XLSX.utils.aoa_to_sheet(createXLSLSummaryObj);

      XLSX.utils.book_append_sheet(wb, wsG_STEI, "STEI");
      XLSX.utils.book_append_sheet(wb, wsG_mahad, "Ma'had Ally");
      XLSX.utils.book_append_sheet(wb, wsG_MA, "MA");
      XLSX.utils.book_append_sheet(wb, wsG_SMPPA, "SMP Putra");
      XLSX.utils.book_append_sheet(wb, wsG_SMPPI, "SMP Putri");
      XLSX.utils.book_append_sheet(wb, wsG_SDQ, "SDQ");
      XLSX.utils.book_append_sheet(wb, wsG_RA, "RA");
      XLSX.utils.book_append_sheet(wb, wsG_BPW, "BPW");
      XLSX.utils.book_append_sheet(wb, wsG_LDS, "LDS");
      XLSX.utils.book_append_sheet(wb, wsG_KY, "Keuangan Yayasan");
      XLSX.utils.book_append_sheet(wb, wsG_SY, "Sekertariat Yayaysan");
      XLSX.utils.book_append_sheet(wb, wsG_HY, "HRD Yayasan");
      XLSX.utils.book_append_sheet(wb, wsG_ADM, "ADM & HUMAS");
      XLSX.utils.book_append_sheet(wb, wsG_SDM, "SDM & Penjaminan Mutu");
      XLSX.utils.book_append_sheet(wb, wsG_RT, "RT");
      XLSX.utils.book_append_sheet(wb, wsG_sarpras, "Sarpras");
      XLSX.utils.book_append_sheet(wb, wsG_usaha, "Usaha");
      XLSX.utils.book_append_sheet(wb, wsG_pengasuhan_pa, "Pengasuhan PA");
      XLSX.utils.book_append_sheet(wb, wsG_pengasuhan_pi, "Pengasuhan PI");
      XLSX.utils.book_append_sheet(wb, wsG_perguruan, "Perguruan");
      XLSX.utils.book_append_sheet(wb, wsG_pustaka, "Pustaka");
      XLSX.utils.book_append_sheet(wb, wsG_laboran, "Laboran");
      XLSX.utils.book_append_sheet(wb, wsG_TPA, "TPA");
      XLSX.utils.book_append_sheet(wb, wsSUM, " SUMMARY");
      XLSX.writeFile(wb, filename);
    },
    async fetchData(order) {
      await this.$http.get("/periode_arsip_kajian", {}).then(result => {
        this.optionPeriod = [];
        result.data.forEach(element => {
          var formatedLabel = element.tgl_arsip
            .split("-")
            .reverse()
            .join("-");
          var temp = {
            value: element.tgl_arsip,
            label: formatedLabel
          };
          this.optionPeriod.push(temp);
        });
      });
      if (this.optionPeriod.length == 0) return;

      this.$q.loading.show({ message: "Loading data" });
      this.orderBy = "id";
      this.orderType = "DESC";

      var src = "%25";
      if (this.search != "") src = this.search;

      await this.$http
        .get(
          "/arsip_list_kajian/" +
            src +
            "/" +
            this.selectPeriod +
            "/" +
            this.orderBy +
            "/" +
            this.orderType +
            "/" +
            (this.activePage - 1) * 10 +
            "/" +
            this.seldiv,
          {}
        )
        .then(result => {
          this.listData = result.data;
        });

      await this.$http
        .get(
          "/count_arsip_kajian/" +
            src +
            "/" +
            this.selectPeriod +
            "/" +
            this.seldiv,
          {}
        )
        .then(result => {
          this.count_page = result.data[0].count;
        });

      await this.$http
        .get(
          "/arsip_list_kajian_all/" +
            src +
            "/" +
            this.selectPeriod +
            "/" +
            this.orderBy +
            "/" +
            this.orderType +
            "/" +
            this.seldiv,
          {}
        )
        .then(result => {
          this.listData_all = [];
          // this.listData_all.push({});
          this.listData_all = result.data;
        });

      await this.$http.get("/jml_guru", {}).then(result => {
        this.count_guru = result.data.count;
      });
      await this.$http
        .get("/jml_kajian/" + src + "/" + this.selectPeriod, {})
        .then(result => {
          this.count_kajian = result.data.count;
        });
      await this.$http
        .get("/jml_hadir/" + this.selectPeriod, {})
        .then(result => {
          this.count_hadir = result.data.count;
        });
      await this.$http
        .get("/jml_izin/" + this.selectPeriod, {})
        .then(result => {
          this.count_izin = result.data.count;
        });
      await this.$http
        .get("/jml_sakit/" + this.selectPeriod, {})
        .then(result => {
          this.count_sakit = result.data.count;
        });
      await this.$http
        .get("/jml_alfa/" + this.selectPeriod, {})
        .then(result => {
          this.count_alfa = result.data.count;
        });
      await this.$http
        .get("/jml_tidak_berjalan/" + this.selectPeriod, {})
        .then(result => {
          this.count_tidak_berjalan = result.data.count;
        });
      await this.$http
        .get("/range_kajian_date" + "/" + this.selectPeriod, {})
        .then(result => {
          this.range_date = result.data;
        });

      this.$q.loading.hide();
    }
  }
};
</script>

<style lang="scss" scoped>
.my-card {
  // min-width: 250px;
  flex-grow: 2;
  // background-color: #B9F6CA;
  background-color: #232979;
  color: #babef2;
}
.q-table--dense .q-table tbody tr,
.q-table--dense .q-table tbody td {
  height: 36px;
}
</style>

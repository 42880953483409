import { render, staticRenderFns } from "./ArsipKajian.vue?vue&type=template&id=348fb802&scoped=true&"
import script from "./ArsipKajian.vue?vue&type=script&lang=js&"
export * from "./ArsipKajian.vue?vue&type=script&lang=js&"
import style1 from "./ArsipKajian.vue?vue&type=style&index=1&id=348fb802&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "348fb802",
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QMarkupTable from 'quasar/src/components/markup-table/QMarkupTable.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import QTable from 'quasar/src/components/table/QTable.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QCard,QCardSection,QSelect,QBtn,QItem,QItemSection,QInput,QMarkupTable,QCardActions,QPagination,QTable});
